import React, {FunctionComponent, useCallback} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {ExceptionInfo} from "../../declerations/internal";
import {supportEmail} from "../../constants/defaultValues";
import {Button} from "@mui/material";
import {useAppSelector} from "../../redux/hooks";
import {selectOperatorId} from "../../redux/auth/selector";

interface IProps {
  exception: ExceptionInfo;
}

const NotificationActionContactSupport: FunctionComponent<IProps> = ({exception}) => {
  const intl = useIntl();

  const operatorId = useAppSelector(selectOperatorId);

  const getSupportSubject = useCallback((): string => {
    return `${
      operatorId
        ? intl.formatMessage({id: "pages.contact-support-error-subject-with-id"}, {id: operatorId})
        : intl.formatMessage({id: "pages.contact-support-error-subject"})
    }`;
  }, [operatorId]);

  const getSupportBody = useCallback((): string => {
    let supportBody = `${intl.formatMessage({id: "pages.support-information"})}:`;
    if (exception.id) {
      supportBody += `%0D%0A${intl.formatMessage({id: "pages.support-error-id"})}:%20${exception.id}`;
    }
    if (exception.key) {
      supportBody += `%0D%0A${intl.formatMessage({id: "pages.support-error-key"})}:%20${exception.key}`;
    }
    supportBody += `%0D%0A${intl.formatMessage({id: "pages.auth-agent"})}:%20${navigator.userAgent}`;
    supportBody += `%0D%0A${intl.formatMessage({id: "pages.support-error-anything-to-add"})}:`;
    supportBody += `%0D%0A${intl.formatMessage({id: "pages.support-error-get-back"})}`;
    return supportBody;
  }, [exception]);

  const contactSupport = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    const url = `mailto:${supportEmail}?subject=${getSupportSubject()}&body=${getSupportBody()}`;
    window.open(url);
  };

  return (
    <Button variant="text" size="small" color="inherit" onClick={contactSupport}>
      <FormattedMessage id="pages.contact-support" />
    </Button>
  );
};
export default NotificationActionContactSupport;
