import {useContext} from "react";
import {EntityContext, EntityContextProps} from "../EntityContext";

const useEntity = (): EntityContextProps => {
  const context = useContext(EntityContext);

  if (!context) throw new Error("EntityContext must be used inside EntityManager");

  return context;
};
export default useEntity;
