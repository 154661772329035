import React, {useState} from "react";
import _ from "lodash";
import {AbstractJpaRO} from "../../../../declerations/server";
import {IPropsEntityColumnData} from "../../../../declerations/props";
import EntityUtils from "../../../helpers/EntityUtils";

const TableDataNumber = <EntityRO extends AbstractJpaRO>({column, item}: IPropsEntityColumnData<EntityRO>) => {
  const [data] = useState<any>(_.get(item, EntityUtils.getColumnDisplayFieldName(column)));

  return <>{data}</>;
};
export default TableDataNumber;
