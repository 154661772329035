import NavigationUtils from "./NavigationUtils";
import _ from "lodash";
import {NavItemProps} from "../layout/app/navs/NavItem";
import {Entity} from "../declerations/entity";

const NavUtils = {
  getNavItem: function (entity: Entity<any>): NavItemProps {
    const readGrant: string | undefined = entity.actions.get("READ")?.grant;
    return {
      id: entity.name,
      icon: <entity.client.icon />, // <SvgIconStyle src={`/static/icons/navbar/${name}.svg`} sx={{ width: '100%', height: '100%' }} />
      label: entity.client.titleKey,
      to: NavigationUtils.getEntityTableUrl(entity),
      grants: readGrant ? [readGrant] : undefined,
    };
  },

  getNavItemGrants: function (navItemProps: NavItemProps): string[] {
    const grants = navItemProps.grants || [];

    if (!navItemProps.subs) {
      return grants;
    }

    if (_.isEmpty(grants) && _.some(navItemProps.subs, (sub) => _.isEmpty(this.getNavItemGrants(sub)))) {
      return grants;
    }

    const subsGrants = _.flattenDeep(navItemProps.subs.map<string[]>((sub) => this.getNavItemGrants(sub)));

    return _.union(grants, subsGrants);
  },

  isNavItemActive: function (navItemProps: NavItemProps, pathName: string): boolean {
    // if (_.isEqual(navItemProps.to, pathName)) {
    if (_.startsWith(pathName, navItemProps.to)) {
      return true;
    }

    if (navItemProps.subs) {
      return _.some(navItemProps.subs, (sub) => this.isNavItemActive(sub, pathName));
    }

    return false;
  },
};
export default NavUtils;
