import {useState} from "react";
import {Outlet} from "react-router-dom";
import {experimentalStyled as styled, useTheme} from "@mui/material/styles";
import AppNavbar from "./AppNavbar";
import AppSidebar from "./AppSidebar";
import {CollapseDrawerContext, CollapseDrawerProvider} from "../../contexts/collapse-drawer/CollapseDrawerContext";
import {NAVBAR_DESKTOP_HEIGHT, NAVBAR_MOBILE_HEIGHT, SIDEBAR_COLLAPSE_WIDTH} from "../../constants/ui";

const RootStyle = styled("div")({
  display: "flex",
  minHeight: "100%",
  overflow: "hidden",
});

const MainStyle = styled("div")(({theme}) => ({
  flexGrow: 1,
  overflow: "auto",
  minHeight: "100%",
  paddingTop: NAVBAR_MOBILE_HEIGHT + 24,
  paddingBottom: theme.spacing(5),
  [theme.breakpoints.up("lg")]: {
    paddingTop: NAVBAR_DESKTOP_HEIGHT + 24,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

type AppLayoutProps = {};

export default function AppLayout({}: AppLayoutProps) {
  const theme = useTheme();

  const [open, setOpen] = useState<boolean>(false);

  return (
    <CollapseDrawerProvider>
      <CollapseDrawerContext.Consumer>
        {({collapseClick}) => (
          <RootStyle>
            <AppNavbar onOpenSidebar={() => setOpen(true)} />
            <AppSidebar isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />
            <MainStyle
              sx={{
                transition: theme.transitions.create("margin", {
                  duration: theme.transitions.duration.complex,
                }),
                ...(collapseClick && {
                  ml: `${SIDEBAR_COLLAPSE_WIDTH}px`,
                }),
              }}
            >
              <Outlet />
            </MainStyle>
          </RootStyle>
        )}
      </CollapseDrawerContext.Consumer>
    </CollapseDrawerProvider>
  );
}
