import {useContext} from "react";
import {CollapseDrawerContext, CollapseDrawerContextProps} from "../CollapseDrawerContext";

const useCollapseDrawer = (): CollapseDrawerContextProps => {
  const context = useContext(CollapseDrawerContext);

  if (!context) throw new Error("CollapseDrawerContext must be used inside CollapseDrawerProvider");

  return context;
};
export default useCollapseDrawer;
