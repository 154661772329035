import {useMemo, useState} from "react";
import {Icon} from "@iconify/react";
import {NavLink as RouterLink, useLocation} from "react-router-dom";
import arrowIosForwardFill from "@iconify/icons-eva/arrow-ios-forward-fill";
import arrowIosDownwardFill from "@iconify/icons-eva/arrow-ios-downward-fill";
import {alpha, useTheme, experimentalStyled as styled} from "@mui/material/styles";
import {Box, List, ListItem, Collapse, ListItemText, ListItemIcon} from "@mui/material";
import typography from "../../../theme/typography";
import _ from "lodash";
import NavUtils from "../../../helpers/NavUtils";
import {FormattedMessage} from "react-intl";
import useGrants from "../../../contexts/grants/hooks/useGrants";

const ListItemStyle = styled(ListItem)(({theme}) => ({
  ...typography.body2,
  height: 48,
  position: "relative",
  textTransform: "capitalize",
  paddingLeft: theme.spacing(5),
  paddingRight: theme.spacing(2.5),
  color: theme.palette.text.secondary,
  "&:before": {
    top: 0,
    right: 0,
    width: 3,
    bottom: 0,
    content: "''",
    display: "none",
    position: "absolute",
    borderTopLeftRadius: 4,
    borderBottomLeftRadius: 4,
    backgroundColor: theme.palette.primary.main,
  },
}));

const ListItemIconStyle = styled(ListItemIcon)({
  width: 22,
  height: 22,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

export interface NavItemProps {
  id: string;
  label: string;
  to: string;
  icon?: JSX.Element;
  info?: JSX.Element;
  subs?: NavItemProps[];
  grants?: string[];
}

export default function NavItem({item, isShow}: {item: NavItemProps; isShow?: boolean}) {
  const {hasGrant} = useGrants();
  const theme = useTheme();
  const {pathname} = useLocation();
  const {label, to, icon, info, subs} = item;

  const isActiveRoot = NavUtils.isNavItemActive(item, pathname);
  const [open, setOpen] = useState<boolean>(isActiveRoot);

  const hasItemGrants = useMemo(() => {
    const menuItemGrants = NavUtils.getNavItemGrants(item);
    return _.isEmpty(menuItemGrants) || _.some(menuItemGrants, (grant) => hasGrant(grant));
  }, [item]);

  const handleOpen = () => {
    setOpen(!open);
  };

  const activeRootStyle = {
    color: "primary.main",
    fontWeight: "fontWeightMedium",
    bgcolor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
    "&:before": {display: "block"},
  };

  const activeSubStyle = {
    color: "text.primary",
    fontWeight: "fontWeightMedium",
  };

  if (!hasItemGrants) {
    return null;
  }

  if (subs) {
    return (
      <>
        <ListItemStyle
          // @ts-ignore
          button
          disableGutters
          onClick={handleOpen}
          sx={{
            ...(isActiveRoot && activeRootStyle),
          }}
        >
          <ListItemIconStyle>{icon && icon}</ListItemIconStyle>

          {isShow && (
            <>
              <ListItemText disableTypography primary={<FormattedMessage id={label} />} />
              {info && info}
              <Box
                component={Icon}
                icon={open ? arrowIosDownwardFill : arrowIosForwardFill}
                sx={{width: 16, height: 16, ml: 1}}
              />
            </>
          )}
        </ListItemStyle>

        {isShow && (
          <Collapse in={open} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {subs.map((sub) => {
                const {id, label, to} = sub;
                const isActiveSub = NavUtils.isNavItemActive(sub, pathname);

                return (
                  <ListItemStyle
                    // @ts-ignore
                    button
                    disableGutters
                    key={id}
                    // @ts-ignore
                    component={RouterLink}
                    to={to}
                    sx={{
                      ...(isActiveSub && activeSubStyle),
                    }}
                  >
                    <ListItemIconStyle>
                      <Box
                        component="span"
                        sx={{
                          width: 4,
                          height: 4,
                          display: "flex",
                          borderRadius: "50%",
                          alignItems: "center",
                          justifyContent: "center",
                          bgcolor: "text.disabled",
                          transition: (theme) => theme.transitions.create("transform"),
                          ...(isActiveSub && {
                            transform: "scale(2)",
                            bgcolor: "primary.main",
                          }),
                        }}
                      />
                    </ListItemIconStyle>
                    <ListItemText disableTypography primary={<FormattedMessage id={label} />} />
                  </ListItemStyle>
                );
              })}
            </List>
          </Collapse>
        )}
      </>
    );
  }

  return (
    <ListItemStyle
      // @ts-ignore
      button
      disableGutters
      // @ts-ignore
      component={RouterLink}
      to={to}
      sx={{
        ...(isActiveRoot && activeRootStyle),
      }}
    >
      <ListItemIconStyle>{icon && icon}</ListItemIconStyle>
      {isShow && (
        <>
          <ListItemText disableTypography primary={<FormattedMessage id={label} />} />
          {info && info}
        </>
      )}
    </ListItemStyle>
  );
}
