import React, {FunctionComponent} from "react";
import {Card, CardContent, CardHeader, CircularProgress} from "@mui/material";
import {FormattedMessage} from "react-intl";

type DashboardGenericCardProps = {
  titleKey: string;
  loading: boolean;
};

const DashboardGenericCard: FunctionComponent<DashboardGenericCardProps> = ({titleKey, loading, children}) => {
  return (
    <Card sx={{height: "100%"}}>
      <CardHeader title={<FormattedMessage id={titleKey} />} />
      {loading ? (
        <CardContent sx={{textAlign: "center"}}>
          <CircularProgress />
        </CardContent>
      ) : (
        children
      )}
    </Card>
  );
};
export default DashboardGenericCard;
