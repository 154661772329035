import {urls} from "../routes/urls";
import {buildUrl, UrlOptions} from "./UrlUtils";
import {Entity} from "../declerations/entity";

const NavigationUtils = {
  getEntityTableUrl: function (entity: Entity<any>, options?: UrlOptions): string {
    const url: string = urls.crudTable.url.replace(":entity", entity.name);
    return buildUrl(url, options);
  },

  getEntityCreateUrl: function (entity: Entity<any>, options?: UrlOptions): string {
    const url: string = urls.crudCreate.url.replace(":entity", entity.name);
    return buildUrl(url, options);
  },

  getEntityDetailsUrl: function (entity: Entity<any>, id?: number, options?: UrlOptions): string {
    const url: string = id
      ? urls.crudDetails.url.replace(":entity", entity.name).replace(":id", id + "")
      : this.getEntityTableUrl(entity);
    return buildUrl(url, options);
  },
};

export default NavigationUtils;
