import {experimentalStyled as styled} from "@mui/material/styles";
import {Box, List, ListSubheader, BoxProps} from "@mui/material";
import typography from "../../../theme/typography";
import NavItem, {NavItemProps} from "./NavItem";
import _ from "lodash";
import NavUtils from "../../../helpers/NavUtils";
import {NavConfig} from "./navConfig";
import {FormattedMessage} from "react-intl";
import useGrants from "../../../contexts/grants/hooks/useGrants";

const ListSubheaderStyle = styled((props) => <ListSubheader disableSticky disableGutters {...props} />)(({theme}) => ({
  ...typography.overline,
  marginTop: theme.spacing(3),
  marginBottom: theme.spacing(2),
  paddingLeft: theme.spacing(5),
  color: theme.palette.text.primary,
}));

interface NavSectionProps extends BoxProps {
  navConfig: NavConfig;
  isShow?: boolean;
}

export default function NavSection({navConfig, isShow = true, ...other}: NavSectionProps) {
  const {hasGrant} = useGrants();

  return (
    <Box {...other}>
      {navConfig.map((list) => {
        const {id, label, items} = list;
        const sectionGrants = _.flattenDeep(items.map<string[]>((item) => NavUtils.getNavItemGrants(item)));
        const hasSectionGrants = _.isEmpty(sectionGrants) || _.some(sectionGrants, (grant) => hasGrant(grant));

        if (!hasSectionGrants) {
          return null;
        }

        return (
          <List key={id} disablePadding>
            {isShow && (
              <ListSubheaderStyle>
                <FormattedMessage id={label} />
              </ListSubheaderStyle>
            )}
            {items.map((item: NavItemProps) => (
              <NavItem key={item.id} item={item} isShow={isShow} />
            ))}
          </List>
        );
      })}
    </Box>
  );
}
