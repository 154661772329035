export default {
  "general.copyright": "OsmolarityMatters.com © 2022 All Rights Reserved.",
  "general.title": "OsmolarityMatters.com Admin",

  "menu.error": "Error",
  "menu.login": "Login",
  "menu.support": "Support",
  "menu.contact-us": "Contact Us",
  "menu.crud": "CRUD",
  "menu.table": "Table",
  "menu.details": "Details",
  "menu.system": "System",
  "menu.account": "Account",
  "menu.operators": "Operators",
  "menu.operator-details": "Operator Details",
  "menu.roles": "Roles",
  "menu.role-details": "Role Details",
  "menu.data": "Data",
  "menu.reports": "Reports",
  "menu.report-details": "Report Details",
  "menu.dashboard": "Dashboard",

  "pages.login-title": "Sign in to OsmolarityMatters.com Admin",
  "pages.login-subtitle": "Enter your details below.",
  "pages.login": "Login",
  "pages.logout": "Logout",
  "pages.email": "Email",
  "pages.password": "Password",
  "pages.full-name": "Full Name",
  "pages.role": "Role",
  "pages.required-field": "Required field",
  "pages.optional-field": "Optional field",
  "pages.value-invalid": "Invalid value",
  "pages.phone-invalid": "Invalid phone and / or prefix",
  "pages.email-invalid": "Invalid email",
  "pages.password-invalid": "Minimum 8 characters, at least one letter and one number",
  "pages.url-invalid": "Invalid URL",
  "pages.type-to-confirm": 'Type "{confirm}" to confirm.',
  "pages.refresh": "Refresh",
  "pages.cancel": "Cancel",
  "pages.continue": "Continue",
  "pages.last-edit": "Last Edit",
  "pages.last-update-time": "Last Update Time",
  "pages.display-options": "Display Options",
  "pages.order-by-colon": "Order By : ",
  "pages.of": "of",
  "pages.yes": "Yes",
  "pages.no": "No",
  "pages.na": "N/A",
  "pages.total": "Total",
  "pages.monthly": "Monthly",
  "pages.amount": "Amount",
  "pages.add": "Add",
  "pages.remove": "Remove",
  "pages.actions": "Actions",
  "pages.preview": "Preview",
  "pages.download": "Download",
  "pages.no-results-found": "No results found",
  "pages.error-retrieving-data": "Error retrieving data",
  "pages.do-not-show-again": "Do not show again.",
  "pages.send-us-an-email": "Send us an email to:",
  "pages.id": "ID",
  "pages.user-agent": "User Agent",
  "pages.messaging-token": "Messaging Token",
  "pages.unique-id": "Unique ID",
  "pages.show": "Show",
  "pages.hide": "Hide",
  "pages.hide-this-forever": "Hide This Forever",
  "pages.send": "Send",
  "pages.close": "Close",
  "pages.submit": "Submit",
  "pages.last": "Last",
  "pages.open": "Open",
  "pages.open-in-new-tab": "Open in New Tab",
  "pages.reopen": "Reopen",
  "pages.create": "Create",
  "pages.delete": "Delete",
  "pages.archive": "Archive",
  "pages.share": "Share",
  "pages.update": "Update",
  "pages.save": "Save",
  "pages.duplicate": "Duplicate",
  "pages.restore": "Restore",
  "pages.publish": "Publish",
  "pages.custom": "Custom",
  "pages.reason": "Reason",
  "pages.more-info": "More Info",
  "pages.select-all": "Select All",
  "pages.deselect-all": "Deselect All",
  "pages.maximum-values-reached": "Maximum values reached",
  "pages.copy-to-clipboard-success": "Copied to clipboard successfully.",
  "pages.copy-to-clipboard-failed": "Copy to clipboard failed.",
  "pages.loading": "Loading...",
  "pages.create-new": "Create new...",
  "pages.error-page-not-found": "Sorry, page not found!",
  "pages.error-page-not-found-explanation":
    "Sorry, we couldn’t find the page you’re looking for. Perhaps you’ve mistyped the URL? Be sure to check your spelling or contact support.",
  "pages.error-title": "Oops... looks like an error occurred!",
  "pages.error-code": "Error code",
  "pages.go-to-home": "Go To Home",
  "pages.down-for-maintenance": "Down For Maintenance",
  "pages.down-for-maintenance-explanation":
    "The system is down for maintenance. We are sorry for the inconvenience. Contact us anytime at {supportEmail}.",
  "pages.network-connection-error": "Network Connection Error",
  "pages.network-connection-error-explanation":
    "Internet connection failed. Please check your internet connection or contact us anytime at {supportEmail}.",
  "pages.loading-account-preferences": "Loading Account Preferences",
  "pages.loading-account-preferences-explanation": "The system is loading your preferences. Please wait a moment.",
  "pages.sign-out": "Sign Out",
  "pages.dark-mode": "Dark Mode",
  "pages.light-mode": "Light Mode",
  "pages.created": "Created",
  "pages.confirm-delete-item": "Are you sure you want to delete this item?",
  "pages.confirm-delete-selected-items": "Are you sure you want to delete the selected items?",
  "pages.no-selected-items": "There are no selected items. Please select items to preform action.",
  "pages.update-selected-items": "Update Selected Items",
  "pages.import": "Import",
  "pages.details": "Details",
  "pages.saving": "Saving",
  "pages.saved": "Saved",
  "pages.pending-changes": "Pending Changes",
  "pages.please-note": "Please Note",
  "pages.some-items-failed-to-delete": "Some items failed to delete.",
  "pages.all-items-failed-to-delete": "All items failed to delete.",
  "pages.some-items-failed-to-update": "Some items failed to update.",
  "pages.all-items-failed-to-update": "All items failed to update.",
  "pages.no-item-is-selected": "No item is selected.",
  "pages.support-id": "Support ID",
  "pages.support-information": "Information for support",
  "pages.support-error-id": "Error ID",
  "pages.support-error-key": "Error key",
  "pages.support-error-anything-to-add": "Additional information you would like to add",
  "pages.support-error-get-back": "Thank you! We will get back to you as soon as possible.",
  "pages.send-support-message": "Send Us A Message",
  "pages.technical-information": "Technical Information",
  "pages.contact-support": "Contact Support",
  "pages.contact-support-subject": "Hello there!",
  "pages.contact-support-subject-with-id": "Hello there! Support ID: {id}",
  "pages.contact-support-error-subject": "Support required",
  "pages.contact-support-error-subject-with-id": "Support required. Support ID: {id}",
  "pages.contact-support-technical-subject": "Technical support",
  "pages.contact-support-technical-subject-with-id": "Technical support. Support ID: {id}",
  "pages.contact-support-tooltip": "We are always here for you!",
  "pages.search": "Search",
  "pages.select": "Select",
  "pages.drop-or-select-file": "Drop or Select file",
  "pages.drop-files-here-or-click": "Drop files here or click browse thorough your machine",
  "pages.get-your-sheet-from-excel": "Get your sheet from Excel / CSV",
  "pages.you-can-use-any-template": "You can use any template you would like",
  "pages.example": "Example",
  "pages.example-total": "Example Total",
  "pages.example-doughnut": "Example Doughnut",
  "pages.example-line": "Example Line",
  "pages.example-bars": "Example Bars",
  "pages.example-table": "Example Table",
  "pages.next": "Next",
  "pages.back": "Back",
  "pages.skip": "Skip",
  "pages.finish": "Finish",
  "pages.view-all": "View All",
  "pages.showing-rows": "Showing {visibleRows} of {totalRows} total rows. All {totalRows} rows will be imported.",
  "pages.showing-rows-max":
    "Showing {visibleRows} of {totalRows} total rows. Please note! You have passed the maximum allowed rows. The maximum of {maxRows} rows will be imported.",
  "pages.import-column-selection": 'Select your "{columnTitle}" column?',
  "pages.import-column-selection-explanation": 'This will become the "{columnTitle}" of each item.',
  "pages.no-items-to-import": "No items to import.",
  "pages.import-invalid-column-values": "Are you sure correct column is selected? It contains invalid values.",
  "pages.uploading-data-now": "Uploading your data now...",
  "pages.data-imported-successfully": "{successCount} of {totalCount} rows imported successfully!",
  "pages.data-import-failed": "Could not import data. Verify your selected columns.",
  "pages.import-check-information": "Check Uploaded Information",
  "pages.import-check-information-explanation": "The process cannot be undone after this step.",
  "pages.process-may-take-several-minutes": "This process may take several minutes.",
  "pages.clear-filters": "Clear Filters",
  "pages.hi-welcome-back": "Hi, Welcome Back",
  "pages.do-not-have-an-account": "Don’t have an account?",
  "pages.admin": "Admin",
  "pages.hi-name": "Hi, {name}",
  "pages.need-help": "Need help?",
  "pages.please-check-our-help-center": "Please check our help center",
  "pages.help-center": "Help Center",
  "pages.general": "General",
  "pages.change-password": "Change Password",
  "pages.old-password": "Old Password",
  "pages.new-password": "New Password",
  "pages.password-changed-successfully": "Password changed successfully",
  "pages.grants": "Grants",
  "pages.permission-denied": "Permission Denied",
  "pages.no-permission-access-page": "You do not have permission to access this page",
  "pages.generate": "Generate",
  "pages.password-protected-pdf": "Password Protected PDF",
  "pages.insert-pdf-password": "Insert the PDF password to view file.",
  "pages.showing-rows-preview": "Showing {visibleRows} of {totalRows} total rows.",
  "pages.unsupported-dashboard-widget": "Unsupported dashboard widget.",
  "pages.your-progress": "Your progress",
  "pages.get-started": "Get Started",
  "pages.number-of-minutes": "{minutes} Minutes",
  "pages.try-again": "Try Again",
  "pages.next-step": "Next Step",
  "pages.completed-step": "Completed!",
  "pages.minimize": "Minimize",
  "pages.congratulations": "Congratulations!",
  "pages.great": "Great!",
  "pages.users": "Users",
  "pages.user-details": "User Details",
  "pages.exam-results": "Osmolarity Scores",
  "pages.exam-results-details": "Osmolarity Scores Details",
  "pages.exams-history": "Osmolarity Scores History",
  "pages.maximum-osmolarity-score": "Maximum Osmolarity Score (mOsm/L)",
  "pages.percent-of-people": "Percent of People",
  "pages.last-osmolarity-score-population": "Last Osmolarity Score in the Population",
  "pages.score-higher-than-percent": "The score is higher than {percent} of others who have been tested.",
  "pages.last-osmolarity-score": "Last Score {score} mOsm/L",
  "pages.total-users": "Total Users",
  "pages.total-exam-results": "Total Osmolarity Scores",
  "pages.users-by-date": "Users by Date",
  "pages.normal-vs-abnormal-results": "Normal / Abnormal Results",
  "pages.exam-results-by-date": "Osmolarity Scores by Date",

  "error.network-error": "Network Error",
  "error.network-connection-lost": "Network connection is lost.",
  "error.connection-error": "Connection Error",
  "error.invalid-file": "Invalid File",
  "error.invalid-file-upload": "Cannot upload selected file.",
  "error.invalid-file-upload-too-large": "Cannot upload selected file. File size too large.",
  "error.invalid-file-upload-too-small": "Cannot upload selected file. File size too small.",
  "error.invalid-file-upload-too-many-files": "Cannot upload selected file. Too many files selected.",
  "error.invalid-file-upload-invalid-type": "Cannot upload selected file. Invalid file type.",
  "error.failed-load-pdf-file": "Failed to load PDF file.",
  "error.failed-load-pdf-page": "Failed to load the page.",
  "error.cannot-open-pdf-password": "Could not open PDF without password.",
  "error.file-type-preview-not-available": ".{fileType} preview is not available. Download file to continue.",
  "error.video-playback-not-supported": "Video playback is not supported by your browser.",
  "error.audio-playback-not-supported": "Audio playback is not supported by your browser.",

  "column.id": "ID",
  "column.creation-time": "Creation Time",
  "column.status": "Status",
  "column.description": "Description",
  "column.name": "Name",
  "column.full-name": "Full Name",
  "column.email": "Email",
  "column.password": "Password",
  "column.admin": "Administrator",
  "column.blocked": "Blocked",
  "column.role": "Role",
  "column.grants": "Grants",
  "column.telephone-prefix": "Telephone Prefix",
  "column.telephone": "Telephone",
  "column.user": "User",
  "column.improvement-comment": "Improvement Comment",
  "column.left-eye": "Left Eye",
  "column.right-eye": "Right Eye",
  "column.exam-time": "Test Time",

  "enum.grants.read-operator": "Read Operator",
  "enum.grants.create-operator": "Create Operator",
  "enum.grants.update-operator": "Update Operator",
  "enum.grants.change-operator-password": "Change Operator Password",
  "enum.operator-role.admin": "Admin",
  "enum.operator-role.manager": "Manager",
};
