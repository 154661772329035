import useGenericRequest from "../base/useGenericRequest";
import {GenericRequestState} from "../../../declerations/request";

function useChangeOperatorPassword(operatorId: number, newPassword: string): GenericRequestState<boolean> {
  return useGenericRequest<boolean>(
    {
      url: `/secure/operator/changePassword`,
      method: "POST",
      data: {
        operatorId: operatorId,
        newPassword: newPassword,
      },
    },
    {
      manual: true,
      resultTransformer: (responseData) => responseData.success,
    }
  );
}
export default useChangeOperatorPassword;
