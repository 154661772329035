import {Dispatch, SetStateAction, useEffect, useState} from "react";
import {addUrlParams, getUrlParam, removeUrlParam} from "../helpers/UrlUtils";
import _ from "lodash";

export type UrlStateOptions<T> = {
  shouldSaveState?: (state: T) => boolean;
  stateParser?: (urlState: string) => T | null;
  stateStringify?: (state: T) => string;
  addToHistory?: boolean;
};

export const useUrlState = <T>(
  urlParam: string,
  defaultValue: T,
  options?: UrlStateOptions<T>
): [T, Dispatch<SetStateAction<T>>] => {
  const [pathname] = useState<string>(window.location.pathname);

  const getInitialState = (): T => {
    let urlParamValue = getUrlParam(urlParam, "");
    if (urlParamValue && options?.stateParser) {
      return options.stateParser(urlParamValue) || defaultValue;
    }
    return defaultValue;
  };

  const [state, setState] = useState<T>(getInitialState());

  const shouldSaveStateInternal = (state: T): boolean => {
    if (options?.shouldSaveState) {
      return options.shouldSaveState(state);
    }
    return !!state;
  };

  const stateTransformerInternal = (state: T): string => {
    if (options?.stateStringify) {
      return options.stateStringify(state);
    }
    if (_.isObject(state)) {
      return JSON.stringify(state);
    }
    return _.toString(state);
  };

  useEffect(() => {
    if (state && shouldSaveStateInternal(state)) {
      addUrlParams({[urlParam]: stateTransformerInternal(state)}, options?.addToHistory);
    } else {
      removeUrlParam(urlParam, options?.addToHistory);
    }
    return () => {
      if (_.isEqual(pathname, window.location.pathname) && !!getUrlParam(urlParam)) {
        removeUrlParam(urlParam, false);
      }
    };
  }, [state]);

  return [state, setState];
};
