import {Icon} from "@iconify/react";
import menu2Fill from "@iconify/icons-eva/menu-2-fill";
import {alpha, experimentalStyled as styled} from "@mui/material/styles";
import {AppBar, Box, IconButton, Stack, Toolbar} from "@mui/material";
import {MHidden} from "../../components/@material-extend";
import AccountPopover from "./navs/AccountPopover";
import LanguagePopover from "./navs/LanguagePopover";
import useCollapseDrawer from "../../contexts/collapse-drawer/hooks/useCollapseDrawer";
import {
  NAVBAR_DESKTOP_HEIGHT,
  NAVBAR_MOBILE_HEIGHT,
  SIDEBAR_COLLAPSE_WIDTH,
  SIDEBAR_DRAWER_WIDTH,
} from "../../constants/ui";

const RootStyle = styled(AppBar)(({theme}) => ({
  boxShadow: "none",
  backdropFilter: "blur(6px)",
  WebkitBackdropFilter: "blur(6px)", // Fix on Mobile
  backgroundColor: alpha(theme.palette.background.default, 0.72),
  [theme.breakpoints.up("lg")]: {
    width: `calc(100% - ${SIDEBAR_DRAWER_WIDTH + 1}px)`,
  },
}));

const ToolbarStyle = styled(Toolbar)(({theme}) => ({
  minHeight: NAVBAR_MOBILE_HEIGHT,
  [theme.breakpoints.up("lg")]: {
    minHeight: NAVBAR_DESKTOP_HEIGHT,
    padding: theme.spacing(0, 5),
  },
}));

// ----------------------------------------------------------------------

type DashboardNavbarProps = {
  onOpenSidebar: VoidFunction;
};

export default function AppNavbar({onOpenSidebar}: DashboardNavbarProps) {
  const {isCollapse} = useCollapseDrawer();

  return (
    <RootStyle
      sx={{
        ...(isCollapse && {
          width: {lg: `calc(100% - ${SIDEBAR_COLLAPSE_WIDTH}px)`},
        }),
      }}
    >
      <ToolbarStyle>
        <MHidden width="lgUp">
          <IconButton onClick={onOpenSidebar} sx={{mr: 1, color: "text.primary"}}>
            <Icon icon={menu2Fill} />
          </IconButton>
        </MHidden>

        <Box sx={{flexGrow: 1}} />

        <Stack direction="row" spacing={{xs: 0.5, sm: 1.5}}>
          {/*<LanguagePopover />*/}
          <AccountPopover />
        </Stack>
      </ToolbarStyle>
    </RootStyle>
  );
}
