import {ReactNode} from "react";
import {useAppSelector} from "../../redux/hooks";
import {selectLoggedIn} from "../../redux/auth/selector";
import {urls} from "../urls";
import {Navigate, useLocation} from "react-router-dom";

type GuestGuardProps = {
  children: ReactNode;
};

interface LocationState {
  origin?: string;
}

export default function GuestGuard({children}: GuestGuardProps) {
  const loggedIn = useAppSelector(selectLoggedIn);
  const location = useLocation();

  if (loggedIn) {
    if (location.state) {
      const {origin} = location.state as LocationState;
      if (origin) {
        return <Navigate to={origin} />;
      }
    }

    return <Navigate to="/" />;
  }

  return <>{children}</>;
}
