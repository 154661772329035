import {UrlInfo} from "../declerations/internal";

function asUrlInfos<T extends {[key: string]: UrlInfo}>(arg: T): T {
  return arg;
}

export const urls = asUrlInfos({
  // Error Root
  error: {
    url: "/error",
    path: "error",
    titleKey: "menu.error",
  },
  // Auth Root
  auth: {
    url: "/auth",
    path: "auth",
    titleKey: "menu.login",
  },
  login: {
    url: "/auth/login",
    path: "login",
    titleKey: "menu.login",
  },
  // App Root
  app: {
    url: "/app",
    path: "app",
    titleKey: "general.title",
  },
  // App CRUD Root
  crud: {
    url: "/app/crud/:entity",
    path: "crud/:entity",
    titleKey: "menu.crud",
  },
  crudTable: {
    url: "/app/crud/:entity/table",
    path: "table",
    titleKey: "menu.table",
  },
  crudCreate: {
    url: "/app/crud/:entity/details",
    path: "details",
    titleKey: "menu.details",
  },
  crudDetails: {
    url: "/app/crud/:entity/details/:id",
    path: "details/:id",
    titleKey: "menu.details",
  },
  // App Dashboard Root
  dashboard: {
    url: "/app/dashboard",
    path: "dashboard",
    titleKey: "menu.dashboard",
  },
  // App Account Root
  account: {
    url: "/app/account",
    path: "account",
    titleKey: "menu.account",
  },
  accountDetails: {
    url: "/app/account/details",
    path: "details",
    titleKey: "menu.account",
  },
});
