import {CrudExampleEnum, CrudExampleStatus, OperatorRO, OperatorRole} from "../declerations/crm";
import {Grants} from "../declerations/grants";
import {EnumInfo, EnumInfoMap} from "../declerations/entity";

// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
// Remember to add new enums to entityEnumMap.ts.
// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!

export const grantsInfoMap: EnumInfoMap<Grants> = new Map<Grants, EnumInfo>([
  ["READ_OPERATOR", {labelKey: "enum.grants.read-operator"}],
  ["CREATE_OPERATOR", {labelKey: "enum.grants.create-operator"}],
  ["UPDATE_OPERATOR", {labelKey: "enum.grants.update-operator"}],
  ["CHANGE_OPERATOR_PASSWORD", {labelKey: "enum.grants.change-operator-password"}],
]);

export const operatorRoleInfoMap: EnumInfoMap<OperatorRole> = new Map<OperatorRole, EnumInfo>([
  ["ADMIN", {labelKey: "enum.operator-role.admin"}],
  ["MANAGER", {labelKey: "enum.operator-role.manager"}],
]);
