import React, {FunctionComponent, useCallback, useMemo, useState} from "react";
import {Alert, Box, Card, CardContent, IconButton, InputAdornment, Stack, TextField, Typography} from "@mui/material";
import {ExamResultRO, OperatorRO, UserRO} from "../../../../declerations/crm";
import {IPropsEntityCustomTab} from "../../../../declerations/props";
import useCrudSearch from "../../../../apis/hooks/crud/useCrudSearch";
import {examResultEntity} from "../../../entities/exam-result.entity";
import LoadingCenter from "../../../../components/common/LoadingCenter";
import {isEmpty} from "lodash";
import {FormattedMessage, useIntl} from "react-intl";
import LineCard from "../../../../components/dashboard/pure/cards/LineCard";
import {Controller, useForm, useWatch} from "react-hook-form";
import useChangePassword from "../../../../apis/hooks/auth/useChangePassword";
import {useUpdateEffect} from "react-use";
import {Icon} from "@iconify/react";
import eyeFill from "@iconify/icons-eva/eye-fill";
import eyeOffFill from "@iconify/icons-eva/eye-off-fill";
import {LoadingButton} from "@mui/lab";
import useChangeOperatorPassword from "../../../../apis/hooks/operator/useChangeOperatorPassword";

type FormValues = {
  newPassword: string;
};

const OperatorChangePasswordTabComponent: FunctionComponent<IPropsEntityCustomTab<OperatorRO>> = ({item}) => {
  const intl = useIntl();

  const [passwordChangedSuccessfully, setPasswordChangedSuccessfully] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState<string[]>([]);

  const isShowPassword = useCallback(
    (fieldName: string): boolean => {
      return showPassword.includes(fieldName);
    },
    [showPassword]
  );

  const handleShowPassword = useCallback(
    (fieldName: string): void => {
      setShowPassword((fieldNames) =>
        fieldNames.includes(fieldName) ? fieldNames.filter((fn) => fn !== fieldName) : [...fieldNames, fieldName]
      );
    },
    [setShowPassword]
  );

  const {handleSubmit, control} = useForm<FormValues>({
    mode: "onBlur",
    reValidateMode: "onChange",
  });

  const newPassword = useWatch({control, name: "newPassword", defaultValue: ""});

  const changePasswordState = useChangeOperatorPassword(item.id, newPassword);

  useUpdateEffect(() => {
    if (changePasswordState.result) {
      setPasswordChangedSuccessfully(true);
    }
  }, [changePasswordState.result]);

  const onSubmit = handleSubmit((data): void => {
    setPasswordChangedSuccessfully(false);
    changePasswordState.executeRequest();
  });

  return (
    <Card>
      <CardContent>
        <form autoComplete="off" noValidate onSubmit={onSubmit}>
          <Stack spacing={{xs: 2, md: 3}}>
            {passwordChangedSuccessfully && (
              <Alert severity="success">
                <FormattedMessage id="pages.password-changed-successfully" />
              </Alert>
            )}

            <Controller
              name="newPassword"
              rules={{required: intl.formatMessage({id: "pages.required-field"})}}
              control={control}
              defaultValue=""
              render={({field: {ref, ...field}, fieldState: {invalid, error}}) => {
                return (
                  <TextField
                    {...field}
                    inputRef={ref}
                    required
                    fullWidth
                    label={intl.formatMessage({id: "pages.new-password"})}
                    type={isShowPassword("newPassword") ? "text" : "password"}
                    autoComplete="off"
                    inputProps={{
                      maxLength: 30,
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={() => handleShowPassword("newPassword")} edge="end">
                            <Icon icon={isShowPassword("newPassword") ? eyeFill : eyeOffFill} />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    error={invalid}
                    helperText={error?.message}
                  />
                );
              }}
            />

            <Box sx={{display: "flex", justifyContent: "flex-end"}}>
              <LoadingButton type="submit" variant="contained" loading={changePasswordState.loading}>
                <FormattedMessage id="pages.change-password" />
              </LoadingButton>
            </Box>
          </Stack>
        </form>
      </CardContent>
    </Card>
  );
};
export default OperatorChangePasswordTabComponent;
