import axios, {AxiosAdapter, AxiosRequestConfig} from "axios";
import {configure} from "axios-hooks";
import {baseUrl} from "../constants/defaultValues";
import _ from "lodash";
import {emptyException, exceptions, genericException} from "../data/exceptions";
import {ExceptionInfo} from "../declerations/internal";
import React, {FunctionComponent, useEffect} from "react";
import NotificationActionContactSupport from "../components/notifications/NotificationActionContactSupport";
import {FormattedMessage} from "react-intl";
import {useAppDispatch, useAppSelector} from "../redux/hooks";
import {logout} from "../redux/auth/slice";
import {selectAuthToken} from "../redux/auth/selector";
import {useSnackbar} from "notistack";
import cacheAdapterEnhancer from "./adapters/cacheAdapterEnhancer";
import throttleAdapterEnhancer from "./adapters/throttleAdapterEnhancer";

export const serverApi = axios.create({
  baseURL: baseUrl,
  adapter: throttleAdapterEnhancer(cacheAdapterEnhancer(axios.defaults.adapter as AxiosAdapter), {
    threshold: 2000,
  }),
});

interface IProps {}

const ServerApiManager: FunctionComponent<IProps> = () => {
  const {enqueueSnackbar} = useSnackbar();

  const dispatch = useAppDispatch();
  const authToken = useAppSelector(selectAuthToken);

  useEffect(() => {
    if (authToken) {
      serverApi.defaults.headers.common["Authorization"] = `Bearer ${authToken}`;
    } else {
      delete serverApi.defaults.headers.common["Authorization"];
    }
  }, [authToken]);

  const logoutHandler = (): void => {
    dispatch(logout());
  };

  const reloadHandler = (): void => {
    window.location.reload();
  };

  const notificationHandler = (exception: ExceptionInfo) => {
    enqueueSnackbar(
      <>
        <FormattedMessage id={exception.textKey || "error.network-connection-lost"} />
        {exception.id && (
          <>
            {" ("}
            <FormattedMessage id="pages.support-id" />
            {`: ${exception.id})`}
          </>
        )}
      </>,
      {
        variant: "warning",
        action: <NotificationActionContactSupport exception={exception} />,
      }
    );
  };

  const errorMessageHandler = (requestId: any, errorObject: any = null): ExceptionInfo => {
    const exceptionKey = _.get(errorObject, "key");

    let exception = _.find(exceptions, (e) => e.key === exceptionKey);
    if (!exception) {
      exception = genericException;
    }

    if (errorObject) {
      exception = _.merge({}, exception, errorObject, !!requestId ? {id: _.toString(requestId)} : {}) as ExceptionInfo;
    }

    if (exception.logout) {
      logoutHandler();
    } else if (exception.reload) {
      reloadHandler();
    } else if (exception.notification) {
      notificationHandler(exception);
    }

    return exception;
  };

  const successHandler = (response: any) => {
    const requestId = _.get(response, "data.requestId");

    const errorObject = _.get(response, "data.errorObject");
    if (errorObject) {
      const exception = errorMessageHandler(requestId, errorObject);
      return Promise.reject(exception);
    }

    const success = _.get(response, "data.success");
    if (success === false) {
      const exception = errorMessageHandler(requestId);
      return Promise.reject(exception);
    }

    return response;
  };

  const errorHandler = (error: any) => {
    const errorKey = _.get(error, "key");
    if (errorKey) {
      // The error was already handled and returned exception info.
      return Promise.reject(error);
    }

    const errorStatus = _.get(error, "response.status");
    if (errorStatus === 401 || errorStatus === 403) {
      return logoutHandler();
    }

    const isCancel = axios.isCancel(error);
    if (isCancel) {
      return Promise.reject(null);
    }

    const errorObject = _.get(error, "response.data.errorObject");
    if (errorObject) {
      const requestId = _.get(error, "response.data.requestId");
      const exception = errorMessageHandler(requestId, errorObject);
      return Promise.reject(exception);
    }

    const exceptionKey = _.get(error, "message");
    const exception = exceptionKey ? errorMessageHandler(null, {key: exceptionKey}) : emptyException;
    return Promise.reject(exception);
  };

  serverApi.interceptors.response.use(successHandler, errorHandler);

  // Axios hooks config
  configure({axios: serverApi, cache: false});

  return null;
};

export default ServerApiManager;
