import {SelfOperatorRO} from "../../declerations/crm";
import {defaultLocale} from "../../constants/defaultValues";
import {RootState} from "../store";

export const selectOperator = (state: RootState): SelfOperatorRO | undefined => state.auth.operator;

export const selectOperatorId = (state: RootState): number | undefined => state.auth.operator?.id;

export const selectAuthToken = (state: RootState): string | undefined => state.auth.authToken;

export const selectLocale = (state: RootState): string => state.auth.locale || defaultLocale;

export const selectLoggedIn = (state: RootState): boolean => !!state.auth.authToken;

export const selectGrants = (state: RootState): string[] => state.auth.operator?.grants || [];

export const selectDarkMode = (state: RootState): boolean => state.auth.darkMode;

export const selectLastRefreshTime = (state: RootState): number => state.auth.lastRefreshTime;
