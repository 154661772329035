import {Link as RouterLink, Outlet} from "react-router-dom";
import {experimentalStyled as styled} from "@mui/material/styles";
import Logo from "../components/svg/Logo";

const HeaderStyle = styled("header")(({theme}) => ({
  top: 0,
  left: 0,
  lineHeight: 0,
  width: "100%",
  position: "absolute",
  padding: theme.spacing(5, 5, 0),
  [theme.breakpoints.down("sm")]: {
    padding: theme.spacing(3, 3, 0),
    display: "flex",
    justifyContent: "center",
  },
}));

export default function LogoOnlyLayout() {
  return (
    <>
      <HeaderStyle>
        <Logo />
      </HeaderStyle>
      <Outlet />
    </>
  );
}
