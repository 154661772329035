import {FunctionComponent, useEffect, useState} from "react";
import _ from "lodash";
import {useLocation, useTitle} from "react-use";
import {urls} from "../routes/urls";
import {useIntl} from "react-intl";
import {matchPath} from "react-router-dom";
import {entityMap} from "../entity/entityMap";

interface IProps {}

const TitleManager: FunctionComponent<IProps> = () => {
  const intl = useIntl();
  const location = useLocation();

  const [title, setTitle] = useState<string>(intl.formatMessage({id: "general.title"}));
  useTitle(title);

  useEffect(() => {
    const pathname = location.pathname;
    if (!pathname) {
      setTitle(intl.formatMessage({id: "general.title"}));
      return;
    }

    const urlInfo = _.findLast(urls, (url) => !!matchPath({path: url.url}, pathname));
    if (!urlInfo) {
      setTitle(intl.formatMessage({id: "general.title"}));
      return;
    }

    let title = `${intl.formatMessage({id: urlInfo.titleKey})} | ${intl.formatMessage({id: "general.title"})}`;

    const match = matchPath({path: urlInfo.url}, pathname);
    const entityParam = match?.params?.entity;
    if (entityParam) {
      const entity = entityMap[entityParam];
      if (entity) {
        title = `${intl.formatMessage({id: entity.client.titleKey})} - ${title}`;
      }
    }

    setTitle(title);
  }, [location.pathname]);

  return null;
};
export default TitleManager;
