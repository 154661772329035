import {Entity} from "../declerations/entity";
import {operatorEntity} from "./entities/operator.entity";
import {userEntity} from "./entities/user.entity";
import {examResultEntity} from "./entities/exam-result.entity";

export const entityMap: {[key: string]: Entity<any>} = {
  [operatorEntity.name]: operatorEntity,
  [userEntity.name]: userEntity,
  [examResultEntity.name]: examResultEntity,
};
