import {Entity, EntityActionConfig, EntityActionType} from "../../declerations/entity";
import {UserRO} from "../../declerations/crm";
import {AddBoxTwoTone, PeopleTwoTone, PieChartTwoTone} from "@mui/icons-material";
import ExampleCustomTabComponent from "../custom/example/tabs/ExampleCustomTabComponent";
import UserDashboardTabComponent from "../custom/user/tabs/UserDashboardTabComponent";

export const userEntity: Entity<UserRO> = {
  name: "User",
  api: {
    type: "User",
    path: "/secure/user",
    cacheName: "user",
    defaultOrders: [{by: "id", descending: true}],
  },
  actions: new Map<EntityActionType, EntityActionConfig>([
    ["READ", {active: true}],
    ["UPDATE", {active: false}],
    ["CREATE", {active: false}],
    ["DELETE", {active: false}],
  ]),
  columns: [
    {
      name: "id",
      type: "Integer",
      titleKey: "column.id",
      sortable: true,
      filterable: true,
      updatable: false,
      updatableMany: false,
      searchable: true,
      required: false,
    },
    {
      name: "telephonePrefix",
      type: "Text",
      titleKey: "column.telephone-prefix",
      sortable: true,
      filterable: true,
      updatable: true,
      updatableMany: true,
      searchable: true,
      required: true,
    },
    {
      name: "telephone",
      type: "Text",
      titleKey: "column.telephone",
      sortable: true,
      filterable: true,
      updatable: true,
      updatableMany: true,
      searchable: true,
      required: true,
    },
    {
      name: "creationTime",
      type: "Date",
      titleKey: "column.creation-time",
      sortable: true,
      filterable: true,
      updatable: false,
      updatableMany: false,
      searchable: false,
      required: false,
    },
  ],
  nestedEntities: [
    {
      entityName: "ExamResult",
      idColumnName: "userId",
    },
  ],
  client: {
    titleKey: "pages.users",
    titleDetailsKey: "pages.user-details",
    icon: PeopleTwoTone,
    showDetailsTab: false,
    generateEmptyEntity: (): UserRO => {
      return {
        id: 0,
        creationTime: 0,
        lastUpdateTime: 0,
        telephonePrefix: "",
        telephone: "",
      };
    },
    generateLabel: (item) => {
      return `(${item.id}) ${item.telephonePrefix}-${item.telephone}`;
    },
    customSummaries: [
      {
        id: "ganeral",
        columns: [{name: "id"}, {name: "creationTime"}, {name: "telephonePrefix"}, {name: "telephone"}],
      },
    ],
    customTabs: [
      {
        id: "dashboard",
        labelKey: "menu.dashboard",
        icon: PieChartTwoTone,
        component: UserDashboardTabComponent,
      },
    ],
    customActions: [],
  },
};
