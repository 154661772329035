import {createSlice, PayloadAction} from "@reduxjs/toolkit";

export const uiSliceName = "ui";

type UiState = {
  loading: string[];
};

const initialState: UiState = {loading: []};

export const uiSlice = createSlice({
  name: uiSliceName,
  initialState: initialState,
  reducers: {
    startLoading: (state, action: PayloadAction<string>) => {
      return {...state, loading: [...state.loading, action.payload]};
    },
    stopLoading: (state, action: PayloadAction<string>) => {
      return {...state, loading: state.loading.filter((x) => x !== action.payload)};
    },
  },
});

export const {startLoading, stopLoading} = uiSlice.actions;

export default uiSlice.reducer;
