import {ExceptionInfo} from "../declerations/internal";

export const genericException: ExceptionInfo = {
  key: "GenericException",
  fullName: "io.crud.crm.exception.GenericException",
  params: [],
  titleKey: "error.network-error",
  textKey: "error.network-connection-lost",
  notification: true,
  logout: false,
  reload: false,
  id: "under-the-sun",
};

export const emptyException: ExceptionInfo = {
  key: "EmptyException",
  fullName: "io.crud.crm.exception.EmptyException",
  params: [],
  titleKey: null,
  textKey: null,
  notification: false,
  logout: false,
  reload: false,
  id: "over-the-bridge",
};

export const exceptions: ExceptionInfo[] = [
  {
    key: "AccessDeniedException",
    fullName: "io.crud.crm.exception.AccessDeniedException",
    params: [
      {
        key: "resourceType",
        type: "java.lang.String",
      },
      {
        key: "resourceId",
        type: "java.lang.String",
      },
    ],
    titleKey: null,
    textKey: null,
    notification: false,
    logout: true,
    reload: false,
    id: null,
  },
  {
    key: "UnauthorizedException",
    fullName: "io.crud.crm.exception.UnauthorizedException",
    params: [],
    titleKey: null,
    textKey: null,
    notification: false,
    logout: true,
    reload: false,
    id: null,
  },
  {
    key: "InvalidTokenException",
    fullName: "studio.crud.auth.common.exception.InvalidTokenException",
    params: [],
    titleKey: null,
    textKey: null,
    notification: false,
    logout: true,
    reload: false,
    id: null,
  },
];
