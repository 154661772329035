import React from "react";
import ReactDOM from "react-dom";
import {Provider as ReduxProvider} from "react-redux";
import ReactGA from "react-ga";
import {firebaseConfig, googleAnalyticsTrackingId} from "./constants/defaultValues";
import firebase from "firebase/app";
import "firebase/messaging";
import "firebase/analytics";
import configureAppStore from "./redux/store";
import App from "./App";
import {PersistGate} from "redux-persist/integration/react";

// Scrollbar
import "react-perfect-scrollbar/dist/css/styles.css";

// Initialize Google Analytics
// ReactGA.initialize(googleAnalyticsTrackingId);

// Initialize Firebase
// firebase.initializeApp(firebaseConfig);
// firebase.analytics();

const {store, persistor} = configureAppStore();
export type AppDispatch = typeof store.dispatch;

ReactDOM.render(
  <ReduxProvider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <App />
    </PersistGate>
  </ReduxProvider>,
  document.getElementById("root")
);
