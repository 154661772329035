import {repeat, replace} from "lodash";
import numeral from "numeral";

export function formatCurrency(number: string | number) {
  return numeral(number).format(Number.isInteger(number) ? "$0,0" : "$0,0.00");
}

export function formatPercent(number: number) {
  return numeral(number / 100).format("0.0%");
}

export function formatNumber(number: string | number) {
  return numeral(number).format();
}

export function formatShortenNumber(number: string | number) {
  return replace(numeral(number).format("0.00a"), ".00", "");
}

export function formatData(number: string | number) {
  return numeral(number).format("0.0 b");
}

export function formatRounding(number: number, rounding: number) {
  return numeral(number).format(`0.${repeat("0", rounding)}`);
}
