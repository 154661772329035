import {all, call, delay, fork, put, select, takeEvery} from "redux-saga/effects";
import {serverApi} from "../../apis/ServerApiManager";
import {SelfOperatorRO} from "../../declerations/crm";
import {PartialDeep} from "type-fest";
import {startLoading, stopLoading} from "../ui/slice";
import {
  authenticate,
  authenticateError,
  authenticateSuccess,
  changeLocale,
  editOperator,
  editOperatorError,
  editOperatorSuccess,
  logout,
  logoutSuccess,
  refreshOperator,
} from "./slice";
import {selectLastRefreshTime, selectLoggedIn, selectOperator} from "./selector";
import {ResultRO} from "../../declerations/server";

const getOperatorAsync = async (): Promise<SelfOperatorRO | null> => {
  let res = await serverApi.get(`/secure/self`);
  let data: ResultRO<SelfOperatorRO> = res.data;
  return data.result || null;
};

function* onAuthenticate({type, payload}: ReturnType<typeof authenticate>) {
  try {
    yield put(startLoading(type));

    // Important for credentials to be available for requests.
    yield delay(100);

    const operator: SelfOperatorRO | null = yield call(getOperatorAsync);
    if (!!operator) {
      yield put(authenticateSuccess({operator: operator}));
    } else {
      yield put(authenticateError());
    }
  } catch (error) {
    yield put(authenticateError());
  } finally {
    yield put(stopLoading(type));
  }
}

function* onRefreshOperator({type, payload}: ReturnType<typeof refreshOperator>) {
  const {force} = payload;

  const loggedIn: boolean = yield select(selectLoggedIn);
  if (!loggedIn) {
    return;
  }

  if (!force) {
    const lastRefreshTime: number = yield select(selectLastRefreshTime);
    const now: number = new Date().getTime();
    if (now - lastRefreshTime < 300_000) {
      return;
    }
  }

  try {
    yield put(startLoading(type));

    const updatedOperator: SelfOperatorRO | null = yield call(getOperatorAsync);
    if (updatedOperator) {
      yield put(editOperatorSuccess({operator: updatedOperator}));
    }
  } catch (error) {
  } finally {
    yield put(stopLoading(type));
  }
}

const editOperatorAsync = async (operator: PartialDeep<SelfOperatorRO>): Promise<SelfOperatorRO | null> => {
  let res = await serverApi.put(`/secure/selfService/me`, operator);
  let data: ResultRO<SelfOperatorRO> = res.data;
  return data.result || null;
};

function* onEditOperator({type, payload}: ReturnType<typeof editOperator>) {
  const {operator} = payload;

  try {
    yield put(startLoading(type));

    const updatedOperator: SelfOperatorRO | null = yield call(editOperatorAsync, operator);
    if (updatedOperator) {
      yield put(editOperatorSuccess({operator: updatedOperator}));
    } else {
      yield put(editOperatorError());
    }
  } catch (error) {
    yield put(editOperatorError());
  } finally {
    yield put(stopLoading(type));
  }
}

function* onChangeLocale({type, payload}: ReturnType<typeof changeLocale>) {
  const {locale} = payload;

  const loggedIn: boolean = yield select(selectLoggedIn);
  if (loggedIn) {
    const operator: SelfOperatorRO = yield select(selectOperator);

    let editOperator = {
      id: operator.id,
      languageIso: locale.toUpperCase(),
    };

    try {
      yield put(startLoading(type));

      const updatedOperator: SelfOperatorRO | null = yield call(editOperatorAsync, editOperator);
      if (updatedOperator) {
        yield put(editOperatorSuccess({operator: updatedOperator}));
      }
    } catch (error) {
    } finally {
      yield put(stopLoading(type));
    }
  }

  // We refresh the page so no need to update the state.
  window.location.reload();
}

function* onLogout({type}: ReturnType<typeof logout>) {
  try {
    yield put(startLoading(type));

    yield put(logoutSuccess());
  } catch (error) {
    yield put(logoutSuccess());
  } finally {
    yield put(stopLoading(type));
  }
}

function* onLogoutSuccess({type}: ReturnType<typeof logoutSuccess>) {
  // We use redirect to clean all state
  window.location.href = "/";
}

export function* watchAuthenticate() {
  yield takeEvery(authenticate, onAuthenticate);
}

export function* watchRefreshOperator() {
  yield takeEvery(refreshOperator, onRefreshOperator);
}

export function* watchEditOperator() {
  yield takeEvery(editOperator, onEditOperator);
}

export function* watchChangeLocale() {
  yield takeEvery(changeLocale, onChangeLocale);
}

export function* watchLogout() {
  yield takeEvery(logout, onLogout);
}

export function* watchLogoutSuccess() {
  yield takeEvery(logoutSuccess, onLogoutSuccess);
}

export default function* rootSaga() {
  yield all([
    fork(watchAuthenticate),
    fork(watchRefreshOperator),
    fork(watchEditOperator),
    fork(watchChangeLocale),
    fork(watchLogout),
    fork(watchLogoutSuccess),
  ]);
}
