import React, {FunctionComponent, useEffect, useMemo} from "react";
import {IntlProvider} from "react-intl";
import locales from "./lang";
import ServerApiManager from "./apis/ServerApiManager";
import TitleManager from "./managers/TitleManager";
import {LocaleInfo} from "./declerations/internal";
import {LocalizationProvider} from "@mui/lab";
import {entityMap} from "./entity/entityMap";
import {entityEnumMap} from "./entity/entityEnumMap";
import NavigationUtils from "./helpers/NavigationUtils";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import {useAppDispatch, useAppSelector} from "./redux/hooks";
import {refreshOperator} from "./redux/auth/slice";
import {selectDarkMode, selectGrants, selectLocale, selectLoggedIn, selectOperatorId} from "./redux/auth/selector";
import ThemeConfig from "./theme";
import RtlLayout from "./components/common/RtlLayout";
import Router from "./routes/routes";
import {BrowserRouter} from "react-router-dom";
import _ from "lodash";
import NotistackProvider from "./components/notifications/NotistackProvider";
import GlobalStyles from "./theme/globalStyles";
import {ProgressBarStyle} from "./components/common/LoadingScreen";
import {BaseOptionChartStyle} from "./components/dashboard/pure/blocks/BaseOptionChart";
import NiceModal from "@ebay/nice-modal-react";
import {operatorEntity} from "./entity/entities/operator.entity";
import {EntityProvider} from "./entity/contexts/entity/EntityContext";
import {GrantsProvider} from "./contexts/grants/GrantsContext";

interface IProps {}

const App: FunctionComponent<IProps> = () => {
  const dispatch = useAppDispatch();

  const loggedIn = useAppSelector(selectLoggedIn);
  const operatorId = useAppSelector(selectOperatorId);
  const grants = useAppSelector(selectGrants, _.isEqual);
  const locale = useAppSelector(selectLocale);
  const darkMode = useAppSelector(selectDarkMode);
  const currentLocale = useMemo<LocaleInfo>(() => locales[locale], [locale]);

  useEffect(() => {
    dispatch(refreshOperator({force: false}));
  }, []);

  return (
    <IntlProvider
      locale={currentLocale.locale}
      messages={currentLocale.messages}
      onError={(err) => {
        if (err.code === "MISSING_TRANSLATION") {
          console.warn("Missing translation", err.message);
          return;
        }
        throw err;
      }}
    >
      <LocalizationProvider dateAdapter={AdapterDateFns} locale={currentLocale.dateLocalization}>
        <ThemeConfig
          isDarkMode={darkMode}
          isRtl={currentLocale.direction === "rtl"}
          localization={currentLocale.materialUiLocalization}
        >
          <RtlLayout>
            <NotistackProvider>
              <GlobalStyles />
              <ProgressBarStyle />
              <BaseOptionChartStyle />

              <ServerApiManager />
              <TitleManager />

              <EntityProvider
                entityMap={entityMap}
                enumMap={entityEnumMap}
                tableRowHeight={52}
                getEntityTableUrl={NavigationUtils.getEntityTableUrl}
                getEntityCreateUrl={NavigationUtils.getEntityCreateUrl}
                getEntityDetailsUrl={NavigationUtils.getEntityDetailsUrl}
              >
                <GrantsProvider
                  loggedIn={loggedIn}
                  loggedInType={operatorEntity.api.type}
                  loggedInId={operatorId}
                  grants={grants}
                >
                  <BrowserRouter>
                    <NiceModal.Provider>
                      <Router />
                    </NiceModal.Provider>
                  </BrowserRouter>
                </GrantsProvider>
              </EntityProvider>
            </NotistackProvider>
          </RtlLayout>
        </ThemeConfig>
      </LocalizationProvider>
    </IntlProvider>
  );
};

export default App;
