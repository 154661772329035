import {MediaFileAclMode, MediaFileRO} from "../../../declerations/server";
import useGenericRequestUpload from "../base/useGenericRequestUpload";
import {useMemo} from "react";
import {GenericRequestStateUpload} from "../../../declerations/request";

interface Options {
  acl?: MediaFileAclMode;
}

function useMediaFileUpload(file: File | undefined, options?: Options): GenericRequestStateUpload<MediaFileRO> {
  const url = useMemo<string>(() => `/mediaFile/upload`, []);
  const data = useMemo<{[key: string]: any}>(() => ({aclMode: options?.acl || "PRIVATE"}), [options]);

  return useGenericRequestUpload<MediaFileRO>(file, url, data);
}
export default useMediaFileUpload;
