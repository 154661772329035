import {Entity, EntityActionConfig, EntityActionType} from "../../declerations/entity";
import {OperatorRO} from "../../declerations/crm";
import {ManageAccountsTwoTone, VpnKeyTwoTone} from "@mui/icons-material";
import OperatorChangePasswordTabComponent from "../custom/operator/tabs/OperatorChangePasswordTabComponent";

export const operatorEntity: Entity<OperatorRO> = {
  name: "Operator",
  api: {
    type: "Operator",
    path: "/secure/operator",
    cacheName: "operator",
    defaultOrders: [{by: "id", descending: true}],
  },
  actions: new Map<EntityActionType, EntityActionConfig>([
    ["READ", {active: true, grant: "READ_OPERATOR"}],
    ["UPDATE", {active: true, grant: "UPDATE_OPERATOR"}],
    ["CREATE", {active: true, grant: "CREATE_OPERATOR"}],
  ]),
  columns: [
    {
      name: "id",
      type: "Integer",
      titleKey: "column.id",
      sortable: true,
      filterable: true,
      updatable: false,
      updatableMany: false,
      searchable: true,
      required: false,
    },
    {
      name: "fullName",
      type: "Text",
      titleKey: "column.full-name",
      sortable: true,
      filterable: true,
      updatable: true,
      updatableMany: false,
      searchable: true,
      required: true,
    },
    {
      name: "email",
      type: "Email",
      titleKey: "column.email",
      sortable: true,
      filterable: true,
      updatable: true,
      updatableMany: false,
      searchable: true,
      required: true,
    },
    {
      name: "password",
      type: "Text",
      titleKey: "column.password",
      sortable: false,
      filterable: false,
      updatable: true,
      updatableMany: false,
      searchable: false,
      required: true,
      hidden: ["Table", "Update"],
    },
    {
      name: "role",
      type: "Enum",
      subtype: "OperatorRole",
      titleKey: "column.role",
      sortable: true,
      filterable: true,
      updatable: true,
      updatableMany: true,
      searchable: false,
      required: true,
    },
    {
      name: "blocked",
      type: "Boolean",
      titleKey: "column.blocked",
      sortable: true,
      filterable: true,
      updatable: true,
      updatableMany: true,
      searchable: false,
      required: true,
      hidden: ["Create"],
    },
    {
      name: "creationTime",
      type: "Date",
      titleKey: "column.creation-time",
      sortable: true,
      filterable: true,
      updatable: false,
      updatableMany: false,
      searchable: false,
      required: false,
    },
  ],
  nestedEntities: [],
  client: {
    titleKey: "menu.operators",
    titleDetailsKey: "menu.operator-details",
    icon: ManageAccountsTwoTone,
    showDetailsTab: true,
    generateEmptyEntity: () => {
      return {
        id: 0,
        creationTime: 0,
        lastUpdateTime: 0,
        fullName: "",
        email: "",
        admin: false,
        blocked: false,
        roleIds: [],
        rolesDisplay: [],
      };
    },
    generateLabel: (item) => {
      return `(${item.id}) ${item.fullName}`;
    },
    customTabs: [
      {
        id: "changePassword",
        grant: "CHANGE_OPERATOR_PASSWORD",
        icon: VpnKeyTwoTone,
        labelKey: "pages.change-password",
        component: OperatorChangePasswordTabComponent,
      },
    ],
  },
};
