import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {SelfOperatorRO} from "../../declerations/crm";
import {getLocale} from "../../helpers/LocaleUtils";
import {PartialDeep} from "type-fest";

export const authSliceName = "auth";

type AuthState = {
  authToken?: string;
  operator?: SelfOperatorRO;
  locale: string;
  darkMode: boolean;
  lastRefreshTime: number;
};

const initialState: AuthState = {
  authToken: undefined,
  operator: undefined,
  locale: getLocale(),
  darkMode: false,
  lastRefreshTime: 0,
};

export const authSlice = createSlice({
  name: authSliceName,
  initialState: initialState,
  reducers: {
    authenticate: (state, action: PayloadAction<{token: string}>) => {
      return {
        ...state,
        authToken: action.payload.token,
      };
    },
    authenticateSuccess: (
      state,
      action: PayloadAction<{
        operator: SelfOperatorRO;
      }>
    ) => {
      return {
        ...state,
        operator: action.payload.operator,
        lastRefreshTime: new Date().getTime(),
      };
    },
    authenticateError: (state) => {
      return {...state, operator: undefined};
    },
    refreshOperator: (state, action: PayloadAction<{force: boolean}>) => {
      return {...state};
    },
    editOperator: (state, action: PayloadAction<{operator: PartialDeep<SelfOperatorRO>}>) => {
      return {...state};
    },
    editOperatorSuccess: (state, action: PayloadAction<{operator: SelfOperatorRO}>) => {
      return {...state, operator: action.payload.operator, lastRefreshTime: new Date().getTime()};
    },
    editOperatorError: (state) => {
      return {...state};
    },
    changeLocale: (state, action: PayloadAction<{locale: string}>) => {
      return {...state, locale: action.payload.locale};
    },
    toggleDarkMode: (state) => {
      return {...state, darkMode: !state.darkMode};
    },
    logout: (state) => {
      return {...state};
    },
    logoutSuccess: (state) => {
      return {
        ...state,
        authToken: undefined,
        operator: undefined,
        lastRefreshTime: 0,
      };
    },
  },
});

export const {
  authenticate,
  authenticateSuccess,
  authenticateError,
  refreshOperator,
  editOperator,
  editOperatorSuccess,
  editOperatorError,
  changeLocale,
  toggleDarkMode,
  logout,
  logoutSuccess,
} = authSlice.actions;

export default authSlice.reducer;
