import {Entity, EntityActionConfig, EntityActionType} from "../../declerations/entity";
import {ExamResultRO} from "../../declerations/crm";
import {TroubleshootTwoTone} from "@mui/icons-material";

export const examResultEntity: Entity<ExamResultRO> = {
  name: "ExamResult",
  api: {
    type: "ExamResult",
    path: "/secure/examResult",
    cacheName: "examResult",
    defaultOrders: [{by: "id", descending: true}],
  },
  actions: new Map<EntityActionType, EntityActionConfig>([
    ["READ", {active: true}],
    ["UPDATE", {active: false}],
    ["CREATE", {active: false}],
    ["DELETE", {active: false}],
  ]),
  columns: [
    {
      name: "id",
      type: "Integer",
      titleKey: "column.id",
      sortable: true,
      filterable: true,
      updatable: false,
      updatableMany: false,
      searchable: true,
      required: false,
    },
    {
      name: "leftEye",
      type: "Integer",
      titleKey: "column.left-eye",
      sortable: true,
      filterable: true,
      updatable: true,
      updatableMany: true,
      searchable: true,
      required: true,
    },
    {
      name: "rightEye",
      type: "Integer",
      titleKey: "column.right-eye",
      sortable: true,
      filterable: true,
      updatable: true,
      updatableMany: true,
      searchable: true,
      required: true,
    },
    {
      name: "improvementComment",
      type: "Text",
      titleKey: "column.improvement-comment",
      sortable: true,
      filterable: true,
      updatable: true,
      updatableMany: true,
      searchable: true,
      required: false,
    },
    {
      name: "userId",
      type: "Entity",
      subtype: "User",
      titleKey: "column.user",
      sortable: true,
      filterable: true,
      updatable: true,
      updatableMany: true,
      required: true,
    },
    {
      name: "examDate",
      type: "Date",
      titleKey: "column.exam-time",
      sortable: true,
      filterable: true,
      updatable: false,
      updatableMany: false,
      searchable: false,
      required: false,
    },
    {
      name: "creationTime",
      type: "Date",
      titleKey: "column.creation-time",
      sortable: true,
      filterable: true,
      updatable: false,
      updatableMany: false,
      searchable: false,
      required: false,
    },
  ],
  nestedEntities: [],
  client: {
    titleKey: "pages.exam-results",
    titleDetailsKey: "pages.exam-results-details",
    icon: TroubleshootTwoTone,
    showDetailsTab: true,
    generateEmptyEntity: (): ExamResultRO => {
      return {
        id: 0,
        creationTime: 0,
        lastUpdateTime: 0,
        examDate: new Date().getTime(),
        improvementComment: "",
        leftEye: undefined,
        rightEye: undefined,
      };
    },
    generateLabel: (item) => {
      return `${item.id}`;
    },
    customSummaries: [],
    customTabs: [],
    customActions: [],
  },
};
