import _, {merge} from "lodash";
import ReactApexChart from "react-apexcharts";
import {Box} from "@mui/material";
import BaseOptionChart from "../blocks/BaseOptionChart";
import {useIntl} from "react-intl";
import DashboardGenericCard from "./DashboardGenericCard";
import {ApexOptions} from "apexcharts";
import {formatRounding} from "../../../../helpers/formatNumber";

type LineCardProps = {
  titleKey: string;
  loading: boolean;
  chartData: {name: string; data: number[]}[];
  xLabels?: string[];
};

export default function LineCard({titleKey, loading, chartData, xLabels}: LineCardProps) {
  const intl = useIntl();

  const chartOptions: ApexOptions = merge(BaseOptionChart(), {
    tooltip: {
      y: {
        formatter: (val: number) => `${formatRounding(val, 0)}`,
      },
    },
    xaxis: {
      categories:
        (!!xLabels && !_.isEmpty(xLabels) && xLabels) ||
        (!_.isEmpty(chartData) &&
          chartData[0].data.map<string>((value, index) => {
            const daysToReduce = chartData[0].data.length - index - 1;
            return intl.formatDate(new Date().getTime() - daysToReduce * 86_400_000);
          })) ||
        [],
    },
  });

  return (
    <DashboardGenericCard titleKey={titleKey} loading={loading}>
      <Box sx={{mt: 3, mx: 3}} dir="ltr">
        <ReactApexChart type="line" series={chartData} options={chartOptions} height={364} />
      </Box>
    </DashboardGenericCard>
  );
}
