import enMessages from "../locales/en_US";
import heMessages from "../locales/he_IL";
import {LocaleInfo} from "../../declerations/internal";
import {heIL} from "@mui/material/locale";
import heLocale from "date-fns/locale/he";

const HeLang: LocaleInfo = {
  id: "he",
  locale: "he-IL",
  messages: {
    ...enMessages,
    ...heMessages,
  },
  name: "עברית",
  shortName: "עב",
  icon: "/static/flags/il.svg",
  direction: "rtl",
  materialUiLocalization: heIL,
  dateLocalization: heLocale,
};
export default HeLang;
