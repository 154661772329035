import {ReactNode} from "react";
import {Navigate, useParams} from "react-router-dom";
import {urls} from "../urls";
import useEntity from "../../entity/contexts/entity/hooks/useEntity";

type EntityGuardProps = {
  children: ReactNode;
};

export default function EntityGuard({children}: EntityGuardProps) {
  const {getEntity} = useEntity();
  const params = useParams();

  if (!params.entity || !getEntity(params.entity)) {
    return <Navigate to={urls.error.url} />;
  }

  return <>{children}</>;
}
