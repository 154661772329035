import React, {FunctionComponent, useMemo} from "react";
import {Box, Stack, Typography} from "@mui/material";
import {ExamResultRO, UserRO} from "../../../../declerations/crm";
import {IPropsEntityCustomTab} from "../../../../declerations/props";
import useCrudSearch from "../../../../apis/hooks/crud/useCrudSearch";
import {examResultEntity} from "../../../entities/exam-result.entity";
import LoadingCenter from "../../../../components/common/LoadingCenter";
import {first, isEmpty} from "lodash";
import {FormattedMessage, useIntl} from "react-intl";
import LineCard from "../../../../components/dashboard/pure/cards/LineCard";
import PopulationBarsCard from "../../../../components/dashboard/pure/cards/PopulationBarsCard";

const UserDashboardTabComponent: FunctionComponent<IPropsEntityCustomTab<UserRO>> = ({item}) => {
  const intl = useIntl();

  const searchState = useCrudSearch<ExamResultRO>(
    examResultEntity,
    1,
    50,
    [
      {fieldName: "userId", operation: "Equal", values: [item.id]},
      {fieldName: "examDate", operation: "IsNotNull"},
    ],
    [{by: "examDate", descending: true}],
    {
      manual: false,
      cache: false,
      throttle: true,
      count: false,
      cacheAutoReload: false,
    }
  );

  const showEmpty = useMemo<boolean>(
    () => searchState.executed && !!searchState.result && isEmpty(searchState.result),
    [searchState.result]
  );

  const showResults = useMemo<boolean>(
    () => !!searchState.result && !isEmpty(searchState.result),
    [searchState.result]
  );

  const lineChartData = useMemo<{name: string; data: number[]}[]>(
    () =>
      searchState.result
        ? [
            {
              name: intl.formatMessage({id: "column.left-eye"}),
              data: searchState.result.reverse().map((r) => r.leftEye || 0),
            },
            {
              name: intl.formatMessage({id: "column.right-eye"}),
              data: searchState.result.reverse().map((r) => r.rightEye || 0),
            },
          ]
        : [],
    [searchState.result]
  );

  const lineXLabels = useMemo<string[]>(
    () => (searchState.result ? searchState.result.reverse().map((r) => intl.formatDate(r.examDate || 0)) : []),
    [searchState.result]
  );

  const populationScore = useMemo<number>(() => {
    const lastResult = first(searchState.result);
    return Math.max(lastResult?.leftEye || 0, lastResult?.rightEye || 0);
  }, [searchState.result]);

  return (
    <Box>
      {searchState.loading && (
        <Box sx={{textAlign: "center"}}>
          <LoadingCenter />
        </Box>
      )}

      {showEmpty && (
        <Typography
          variant="body2"
          component="div"
          sx={{
            textAlign: "center",
            color: "text.disabled",
            position: "absolute",
            left: 0,
            right: 0,
            my: 1,
          }}
        >
          <FormattedMessage id="pages.no-results-found" />
        </Typography>
      )}

      {showResults && (
        <Stack spacing={4}>
          <LineCard titleKey="pages.exams-history" loading={false} chartData={lineChartData} xLabels={lineXLabels} />
          <PopulationBarsCard score={populationScore} />
        </Stack>
      )}
    </Box>
  );
};
export default UserDashboardTabComponent;
