import merge from "lodash/merge";
import React, {useMemo} from "react";
import ReactApexChart from "react-apexcharts";
import {Box, Card, CardHeader, CardProps, Typography} from "@mui/material";
import {ApexOptions} from "apexcharts";
import {useTheme} from "@mui/material/styles";
import BaseOptionChart from "../blocks/BaseOptionChart";
import {formatPercent, formatRounding} from "../../../../helpers/formatNumber";
import {sum} from "lodash";
import {FormattedMessage, useIntl} from "react-intl";

type OsmolarityPercent = {
  osmolarityScore: number;
  percent: number;
};

const data: OsmolarityPercent[] = [
  {
    osmolarityScore: 275,
    percent: 0.1,
  },
  {
    osmolarityScore: 280,
    percent: 1.1,
  },
  {
    osmolarityScore: 285,
    percent: 2.3,
  },
  {
    osmolarityScore: 290,
    percent: 5.5,
  },
  {
    osmolarityScore: 295,
    percent: 9.2,
  },
  {
    osmolarityScore: 300,
    percent: 14.1,
  },
  {
    osmolarityScore: 305,
    percent: 14.7,
  },
  {
    osmolarityScore: 310,
    percent: 13.3,
  },
  {
    osmolarityScore: 315,
    percent: 10.9,
  },
  {
    osmolarityScore: 320,
    percent: 8.5,
  },
  {
    osmolarityScore: 325,
    percent: 5.8,
  },
  {
    osmolarityScore: 330,
    percent: 4.2,
  },
  {
    osmolarityScore: 335,
    percent: 2.5,
  },
  {
    osmolarityScore: 340,
    percent: 1.9,
  },
  {
    osmolarityScore: 345,
    percent: 1.3,
  },
  {
    osmolarityScore: 350,
    percent: 0.9,
  },
  {
    osmolarityScore: 355,
    percent: 0.7,
  },
  {
    osmolarityScore: 360,
    percent: 0.7,
  },
  {
    osmolarityScore: 365,
    percent: 0.6,
  },
  {
    osmolarityScore: 370,
    percent: 0.3,
  },
  {
    osmolarityScore: 375,
    percent: 0.3,
  },
  {
    osmolarityScore: 380,
    percent: 0.3,
  },
  {
    osmolarityScore: 385,
    percent: 0.3,
  },
  {
    osmolarityScore: 390,
    percent: 0.2,
  },
  {
    osmolarityScore: 395,
    percent: 0.2,
  },
  {
    osmolarityScore: 400,
    percent: 0.2,
  },
];

interface Props extends CardProps {
  score: number;
}

export default function PopulationBarsCard({score, ...other}: Props) {
  const theme = useTheme();
  const intl = useIntl();

  const higherPercent = useMemo<number>(
    () => sum(data.filter((x) => x.osmolarityScore <= score).map((x) => x.percent)),
    [score]
  );

  const annotations = useMemo<{x: string; text: string}[]>(() => {
    const findClosest = (x: number): OsmolarityPercent => {
      return data.reduce((best, current) => {
        return Math.abs(current.osmolarityScore - x) < Math.abs(best.osmolarityScore - x) ? current : best;
      }, data[0]);
    };
    return [
      {
        x: findClosest(score).osmolarityScore.toString(),
        text: intl.formatMessage({id: "pages.last-osmolarity-score"}, {score: score}),
      },
    ];
  }, [score]);

  const chartLabels = useMemo<string[]>(() => data.map((x) => x.osmolarityScore.toString()), []);
  const chartData = useMemo<
    {
      name: string;
      data: number[];
    }[]
  >(
    () => [
      {
        name: "",
        data: data.map((x) => x.percent),
      },
    ],
    []
  );

  const overrideOptionChart: ApexOptions = {
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },
    xaxis: {
      title: {
        text: intl.formatMessage({id: "pages.maximum-osmolarity-score"}),
      },
      categories: chartLabels,
    },
    yaxis: {
      title: {
        text: intl.formatMessage({id: "pages.percent-of-people"}),
      },
      labels: {
        formatter: function (value) {
          return formatRounding(value, 1);
        },
      },
    },
    tooltip: {
      y: {
        formatter: (val: number) => `${val}%`,
      },
    },
    annotations: {
      xaxis: annotations.map((a) => ({
        x: a.x,
        borderColor: theme.palette.secondary.main,
        label: {
          borderColor: theme.palette.secondary.main,
          style: {
            fontSize: "16px",
            color: theme.palette.common.white,
            background: theme.palette.secondary.main,
          },
          text: a.text,
          orientation: "horizontal",
        },
      })),
    },
  };

  const chartOptions = merge(BaseOptionChart(), overrideOptionChart);

  return (
    <Card {...other}>
      <CardHeader
        title={<FormattedMessage id={"pages.last-osmolarity-score-population"} />}
        subheader={
          <FormattedMessage
            id={"pages.score-higher-than-percent"}
            values={{
              percent: (
                <Typography component={"span"} sx={{color: "primary.main"}} fontSize={24}>
                  {formatPercent(higherPercent)}
                </Typography>
              ),
            }}
          />
        }
      />

      <Box sx={{mt: 3, mx: 3}} dir="ltr">
        <ReactApexChart type="bar" series={chartData} options={chartOptions} height={364} />
      </Box>
    </Card>
  );
}
