import React, {Suspense, lazy} from "react";
import {Navigate, Outlet, useRoutes} from "react-router-dom";
import GuestGuard from "./guards/GuestGuard";
import AuthGuard from "./guards/AuthGuard";
import LoadingScreen from "../components/common/LoadingScreen";
import {urls} from "./urls";
import AppLayout from "../layout/app";
import LogoOnlyLayout from "../layout/LogoOnlyLayout";
import EntityGuard from "./guards/EntityGuard";
import {useAppSelector} from "../redux/hooks";
import {selectLoggedIn} from "../redux/auth/selector";
import componentLoader from "../helpers/ComponentLoader";

const Loadable = (Component: any) => (props: any) => {
  return (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  const loggedIn = useAppSelector(selectLoggedIn);

  return useRoutes([
    {
      path: "/",
      element: <Navigate to={loggedIn ? urls.app.url : urls.login.url} replace />,
    },

    // Auth Routes
    {
      path: urls.auth.path,
      element: (
        <GuestGuard>
          <Outlet />
        </GuestGuard>
      ),
      children: [
        {path: "", element: <Navigate to={urls.login.url} replace />},
        {
          path: urls.login.path,
          element: <Login />,
        },
      ],
    },

    // App Routes
    {
      path: urls.app.path,
      element: (
        <AuthGuard>
          <AppLayout />
        </AuthGuard>
      ),
      children: [
        {path: "", element: <Navigate to={urls.dashboard.url} replace />},
        {
          path: urls.dashboard.path,
          element: <Dashboard />,
        },
        {
          path: urls.crud.path,
          element: (
            <EntityGuard>
              <Outlet />
            </EntityGuard>
          ),
          children: [
            {path: "", element: <Navigate to={urls.error.url} replace />},
            {path: urls.crudTable.path, element: <CrudTable key={Math.random()} />},
            {path: urls.crudDetails.path, element: <CrudDetails key={Math.random()} />},
            {path: urls.crudCreate.path, element: <CrudDetails key={Math.random()} />},
          ],
        },
        {
          path: urls.account.path,
          children: [
            {path: "", element: <Navigate to={urls.accountDetails.url} replace />},
            {path: urls.accountDetails.path, element: <AccountDetails />},
          ],
        },
      ],
    },

    // General Routes
    {
      path: "*",
      element: <LogoOnlyLayout />,
      children: [
        {path: urls.error.path, element: <Error />},
        {path: "*", element: <Navigate to={urls.error.url} replace />},
      ],
    },
    {path: "*", element: <Navigate to={urls.error.url} replace />},
  ]);
}

// IMPORT COMPONENTS

// AUTH
const Login = Loadable(lazy(() => componentLoader(() => import("../pages/auth/login"))));

// APP

// DASHBOARD
const Dashboard = Loadable(lazy(() => componentLoader(() => import("../pages/app/dashboard/dashboard"))));

// CRUD
const CrudTable = Loadable(lazy(() => componentLoader(() => import("../pages/app/crud/crud-table"))));
const CrudDetails = Loadable(lazy(() => componentLoader(() => import("../pages/app/crud/crud-details"))));

// ACCOUNT
const AccountDetails = Loadable(lazy(() => componentLoader(() => import("../pages/app/account/account-details"))));

// GENERAL

// ERRORS
const Error = Loadable(lazy(() => componentLoader(() => import("../pages/general/error"))));
