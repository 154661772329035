import {AssessmentTwoTone} from "@mui/icons-material";
import {NavItemProps} from "./NavItem";
import {urls} from "../../../routes/urls";
import NavUtils from "../../../helpers/NavUtils";
import {operatorEntity} from "../../../entity/entities/operator.entity";
import {userEntity} from "../../../entity/entities/user.entity";
import {examResultEntity} from "../../../entity/entities/exam-result.entity";

export type NavConfig = {
  id: string;
  label: string;
  items: NavItemProps[];
}[];

const navConfig: NavConfig = [
  {
    id: "data",
    label: "menu.data",
    items: [
      {
        id: "dashboard",
        icon: <AssessmentTwoTone />,
        label: urls.dashboard.titleKey,
        to: urls.dashboard.url,
      },
    ],
  },
  {
    id: "users",
    label: "pages.users",
    items: [NavUtils.getNavItem(userEntity), NavUtils.getNavItem(examResultEntity)],
  },
  {
    id: "operators",
    label: "menu.operators",
    items: [NavUtils.getNavItem(operatorEntity)],
  },
];
export default navConfig;
