export const defaultLocale = "en";
export const tableCheckboxCellWidth = 75;
export const tableCellWidth = 175;
export const pageSizes = [10, 25, 50];
export const maxImportRows = 1000;

export const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY || "AIzaSyCrMUvZzM04gOhtNHPxuuRXn0j91YGcyWU",
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN || "doxin-server.firebaseapp.com",
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL || "https://doxin-server.firebaseio.com",
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID || "doxin-server",
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET || "doxin-server.appspot.com",
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID || "135393926011",
  appId: process.env.REACT_APP_FIREBASE_APP_ID || "1:135393926011:web:60db2cb08614ad4e673bde",
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID || "G-95BFGB47ZL",
};
export const firebaseMessagingApiKey =
  process.env.REACT_APP_FIREBASE_MESSAGING_API_KEY ||
  "BKsD4VPbYYcAFzQnxNe8yrly5UsNoeoh3djCFpGQp-_MXAIJrd1MyQSnPoQRlLsht8gGDvyne2xRufQ1YZFfJF4";

export const googleClientId =
  process.env.REACT_APP_GOOGLE_CLIENT_ID || "135393926011-cu67q2kc4f57fmt9phktsai6mildh04f.apps.googleusercontent.com";
export const googleApiKey = process.env.REACT_APP_GOOGLE_API_KEY || "lUCVtvTjCpGR3Pb1qGsSkoSw";
export const googleAnalyticsTrackingId = process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID || "UA-178880365-2";

export const baseUrl = process.env.REACT_APP_API_URL || "https://crm-api.osmolaritymatters.com";
export const supportEmail = process.env.REACT_APP_SUPPORT_EMAIL || "support@osmolaritymatters.com";
