import {Icon} from "@iconify/react";
import {useCallback, useRef, useState} from "react";
import personFill from "@iconify/icons-eva/person-fill";
import sunFill from "@iconify/icons-eva/sun-fill";
import moonFill from "@iconify/icons-eva/moon-fill";
import {Link as RouterLink} from "react-router-dom";
import {alpha} from "@mui/material/styles";
import {Button, Box, Divider, MenuItem, Typography} from "@mui/material";
import {MIconButton} from "../../../components/@material-extend";
import MenuPopover from "../../../components/common/MenuPopover";
import {useAppDispatch, useAppSelector} from "../../../redux/hooks";
import {selectDarkMode, selectOperator} from "../../../redux/auth/selector";
import MyAvatar from "../../../components/common/MyAvatar";
import {logout, toggleDarkMode} from "../../../redux/auth/slice";
import {FormattedMessage} from "react-intl";
import {urls} from "../../../routes/urls";

export default function AccountPopover() {
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);

  const dispatch = useAppDispatch();
  const operator = useAppSelector(selectOperator);
  const darkMode = useAppSelector(selectDarkMode);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleToggleDarkMode = useCallback((): void => {
    handleClose();
    dispatch(toggleDarkMode());
  }, []);

  const handleLogout = useCallback((): void => {
    handleClose();
    dispatch(logout());
  }, []);

  return (
    <>
      <MIconButton
        ref={anchorRef}
        onClick={handleOpen}
        sx={{
          padding: 0,
          width: 44,
          height: 44,
          ...(open && {
            "&:before": {
              zIndex: 1,
              content: "''",
              width: "100%",
              height: "100%",
              borderRadius: "50%",
              position: "absolute",
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72),
            },
          }),
        }}
      >
        <MyAvatar />
      </MIconButton>

      <MenuPopover open={open} onClose={handleClose} anchorEl={anchorRef.current} sx={{width: 220}}>
        <Box sx={{my: 1.5, px: 2.5}}>
          <Typography variant="subtitle1" noWrap>
            {operator?.fullName}
          </Typography>
          <Typography variant="body2" sx={{color: "text.secondary"}} noWrap>
            {operator?.email}
          </Typography>
        </Box>

        <Divider sx={{my: 1}} />

        <MenuItem
          to={urls.account.url}
          component={RouterLink}
          onClick={handleClose}
          sx={{typography: "body2", py: 1, px: 2.5}}
        >
          <Box
            component={Icon}
            icon={personFill}
            sx={{
              mr: 2,
              width: 24,
              height: 24,
            }}
          />
          <FormattedMessage id="menu.account" />
        </MenuItem>

        <MenuItem onClick={handleToggleDarkMode} sx={{typography: "body2", py: 1, px: 2.5}}>
          <Box
            component={Icon}
            icon={darkMode ? sunFill : moonFill}
            sx={{
              mr: 2,
              width: 24,
              height: 24,
            }}
          />
          {darkMode ? <FormattedMessage id="pages.light-mode" /> : <FormattedMessage id="pages.dark-mode" />}
        </MenuItem>

        <Box sx={{p: 2, pt: 1.5}}>
          <Button fullWidth color="inherit" variant="outlined" onClick={handleLogout}>
            <FormattedMessage id="pages.logout" />
          </Button>
        </Box>
      </MenuPopover>
    </>
  );
}
