import {useEffect, useMemo} from "react";
import {Link as RouterLink, useLocation} from "react-router-dom";
import {alpha, experimentalStyled as styled} from "@mui/material/styles";
import {Box, Drawer, Link, Stack, Tooltip, Typography} from "@mui/material";
import {MHidden} from "../../components/@material-extend";
import Logo from "../../components/svg/Logo";
import MyAvatar from "../../components/common/MyAvatar";
import {useAppSelector} from "../../redux/hooks";
import {selectOperator} from "../../redux/auth/selector";
import {urls} from "../../routes/urls";
import NavSection from "./navs/NavSection";
import navConfig from "./navs/navConfig";
import useCollapseDrawer from "../../contexts/collapse-drawer/hooks/useCollapseDrawer";
import IconCollapse from "./navs/IconCollapse";
import {SIDEBAR_COLLAPSE_WIDTH, SIDEBAR_DRAWER_WIDTH} from "../../constants/ui";
import PerfectScrollbar from "react-perfect-scrollbar";
import {operatorRoleInfoMap} from "../../constants/enums";
import {FormattedMessage, useIntl} from "react-intl";

const RootStyle = styled("div")(({theme}) => ({
  [theme.breakpoints.up("lg")]: {
    flexShrink: 0,
    width: SIDEBAR_DRAWER_WIDTH,
  },
}));

const AccountStyle = styled("div")(({theme}) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(2, 2.5),
  borderRadius: theme.shape.borderRadiusSm,
  backgroundColor: theme.palette.grey[500_12],
}));

type DashboardSidebarProps = {
  isOpenSidebar: boolean;
  onCloseSidebar: VoidFunction;
};

export default function AppSidebar({isOpenSidebar, onCloseSidebar}: DashboardSidebarProps) {
  const intl = useIntl();
  const {pathname} = useLocation();

  const operator = useAppSelector(selectOperator);
  const {isCollapse, collapseClick, collapseHover, onToggleCollapse, onHoverEnter, onHoverLeave} = useCollapseDrawer();

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
  }, [pathname]);

  const roleLabel = useMemo<string>(
    () =>
      intl.formatMessage({
        id: operator?.role ? operatorRoleInfoMap.get(operator.role)?.labelKey || "pages.na" : "pages.na",
      }),
    [operator]
  );

  const renderContent = (
    <PerfectScrollbar options={{suppressScrollX: true, wheelPropagation: false}}>
      <Stack
        spacing={3}
        sx={{
          px: 2.5,
          pt: 3,
          pb: 2,
          ...(isCollapse && {
            alignItems: "center",
          }),
        }}
      >
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Box sx={{display: "inline-flex"}}>
            <Logo />
          </Box>

          <MHidden width="lgDown">
            {!isCollapse && <IconCollapse onToggleCollapse={onToggleCollapse} collapseClick={collapseClick} />}
          </MHidden>
        </Stack>

        {isCollapse ? (
          <MyAvatar sx={{mx: "auto", mb: 2}} />
        ) : (
          <Link underline="none" component={RouterLink} to={urls.account.url}>
            <AccountStyle>
              <MyAvatar />
              <Box sx={{ml: 2, overflow: "hidden"}}>
                <Typography variant="subtitle2" sx={{color: "text.primary"}} noWrap>
                  {operator?.fullName}
                </Typography>
                <Tooltip title={roleLabel}>
                  <Typography variant="body2" sx={{color: "text.secondary"}} noWrap>
                    {roleLabel}
                  </Typography>
                </Tooltip>
              </Box>
            </AccountStyle>
          </Link>
        )}
      </Stack>

      <NavSection navConfig={navConfig} isShow={!isCollapse} />
    </PerfectScrollbar>
  );

  return (
    <RootStyle
      sx={{
        width: {
          lg: isCollapse ? SIDEBAR_COLLAPSE_WIDTH : SIDEBAR_DRAWER_WIDTH,
        },
        ...(collapseClick && {
          position: "absolute",
        }),
      }}
    >
      <MHidden width="lgUp">
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: {width: SIDEBAR_DRAWER_WIDTH},
          }}
        >
          {renderContent}
        </Drawer>
      </MHidden>

      <MHidden width="lgDown">
        <Drawer
          open
          variant="persistent"
          onMouseEnter={onHoverEnter}
          onMouseLeave={onHoverLeave}
          PaperProps={{
            sx: {
              width: SIDEBAR_DRAWER_WIDTH,
              bgcolor: "background.default",
              ...(isCollapse && {
                width: SIDEBAR_COLLAPSE_WIDTH,
              }),
              ...(collapseHover && {
                borderRight: 0,
                backdropFilter: "blur(6px)",
                WebkitBackdropFilter: "blur(6px)", // Fix on Mobile
                boxShadow: (theme) => theme.customShadows.z20,
                bgcolor: (theme) => alpha(theme.palette.background.default, 0.88),
              }),
            },
          }}
        >
          {renderContent}
        </Drawer>
      </MHidden>
    </RootStyle>
  );
}
