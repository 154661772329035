import enLang from "./entries/en-US";
import heLang from "./entries/he-IL";
import {LocaleInfo} from "../declerations/internal";

const locales: {[key: string]: LocaleInfo} = {
  en: enLang,
  he: heLang,
};

export default locales;
