import {useAppSelector} from "../../redux/hooks";
import {selectOperator} from "../../redux/auth/selector";
import {MAvatarProps} from "../@material-extend/MAvatar";
import createAvatar from "../../helpers/createAvatar";
import {MAvatar} from "../@material-extend";
import {useIntl} from "react-intl";
import {useMemo} from "react";

// ----------------------------------------------------------------------

export default function MyAvatar({...other}: MAvatarProps) {
  const intl = useIntl();

  const operator = useAppSelector(selectOperator);
  const fullName = useMemo<string>(() => operator?.fullName || intl.formatMessage({id: "pages.na"}), [operator]);
  const profileImageUrl = useMemo<string | undefined>(() => undefined, [operator]);

  return (
    <MAvatar
      src={profileImageUrl}
      alt={operator?.fullName}
      color={profileImageUrl ? "default" : createAvatar(fullName).color}
      {...other}
    >
      {createAvatar(fullName).name}
    </MAvatar>
  );
}
